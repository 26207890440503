import React, {useState} from 'react';
import Fade from '@mui/material/Fade';
import { Box, Fab, useScrollTrigger } from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';

  
export const BackToTopButton = () =>{
  
  const trigger = useScrollTrigger()
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  
  return (
    <div>
      <Fade in={trigger}>
        <Box
            onClick={()=>{scrollToTop()}}
            role="presentation"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
        >
            <Fab size="medium" aria-label="scroll back to top">
                <KeyboardArrowUp  />
            </Fab>
        </Box>
      </Fade>
    </div>
  )

}
  
