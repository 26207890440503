import { Box, Fab, Grid, Typography } from "@mui/material"
import HeroImage from '../assets/images/landing-bg.webp'
import { Facebook, Instagram } from "@mui/icons-material"
import { Demodal } from "demodal"
import { SquareBookingModal } from "../components/booking-modal/SquareBookingModal"
import { itemFocusedAnimation } from "../components/common/focus"
import { ShopCarousel } from "../components/carousel/Carousel"



export const Home = () => {

    return(
        <Box sx={{width:'auto', justifyContent:'center', alignItems:'center', textAlign:'center'}}>
            <Grid container >
                <Grid item  sx={{justifyContent:'center', alignItems:'center', textAlign:'center'}}>
                    <Grid container 
                        sx={{
                            marginTop: '10px',
                            paddingTop:{xs:'20%', sm:'10%'},
                            paddingBottom:{xs:'5%', sm:'5%'},
                            minHeight:{ xs: '500px', sm: '600px', md: '600px', lg: '600px' },
                            height:{ xs: 'auto', sm: '50vh', md: '50vh', lg: '75vh' },
                            width: '100vw',
                            //backgroundImage: `url(${HeroImage})`,
                            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.55) 50%, rgba(0, 0, 0, 0.05) 100%), url(${HeroImage})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: -1,
                        }}
                    >
                        
                        <Grid item xs={12}>
                            <Typography 
                                sx={{
                                    fontSize: `clamp(1.5625rem, 1.3118rem + 1.8235vw, 3.5rem)`,

                                    fontWeight: 'bold',
                                    margin: 'auto', padding: 'auto', paddingLeft: '5%', paddingRight: '5%', marginBottom: '15px',
                                    fontFamily: 'Archivo Black, Arial, sans-serif', lineHeight: '1.2'
                                }}
                            >
                                HAIR STYLING, SHAVES AND COLOR
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: 'clamp(1.25rem, 1.2176rem + 0.2353vw, 1.5rem)',
                                    fontWeight: 'bold', lineHeight: '1.2',
                                    margin: 'auto', padding: 'auto', paddingLeft: '5%', paddingRight: '5%',
                                }}
                            >
                                Full service neighborhood barbershop
                            </Typography>
                            <Box
                                aria-label='Book an appointment via the square booking modal'
                                sx={{
                                    margin: 'auto', marginTop: '60px',
                                    padding: 'auto',
                                    alignItems: 'center', justifyContent:'center',
                                    minWidth: '180px',
                                    fontWeight: 'bold',
                                    width: {
                                        xs: '80%',
                                        md: '80px'
                                    },
                                    height: '60px',
                                    transition: 'all .3s ease-in-out',
                                    display: 'flex',
                                    cursor: 'pointer',
                                    filter: 'drop-shadow(0px 0px 3px rgba(0,0,0,.6))',
                                    backgroundColor: '#404040',
                                    '&:hover':{
                                        backgroundColor: '#585858',
                                        filter: 'drop-shadow(0px 0px 6px rgba(0,0,0,1))',
                                    },
                                    '&:focus':{
                                    
                                        animation:`${itemFocusedAnimation} 2s ease-in-out infinite`
                                    },
                                }}
                                onClick={() => {Demodal.open(SquareBookingModal)}}
                                onKeyDownCapture = {(e) => {
                                    if (e.key === 'Enter') {
                                        Demodal.open(SquareBookingModal)
                                    }
                                    else if (e.key === ' ') {
                                        Demodal.open(SquareBookingModal)
                                    }       
                                }}
                                tabIndex={0}
                            >
                                BOOK NOW
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid 
                    item xs={12} 
                    sx={{
                        backgroundColor: '#161616', 
                        justifyContent:'center', alignItems:'center', textAlign:'center', 
                        padding:'auto', paddingTop:'5%'
                    }}
                >
                    <Typography 
                        sx={{
                            fontSize: { xs: '28px', sm: '33px', md: '39px', lg: '42px' },
                            fontWeight: 'bold',
                            margin: 'auto', padding: 'auto', paddingLeft: '5%', paddingRight: '5%', marginLeft: '5%', marginRight: '5%', 
                            fontFamily: 'Archivo Black, Arial, sans-serif',
                            color: '#636363'
                        }}
                    >
                        FOLLOW US ON INSTAGRAM
                    </Typography>
                    <Box sx={{paddingBottom:'5%'}}>
                       
                        <Fab
                          aria-label='Visit us on Facebook in a new tab'
                            sx={{
                                bgcolor:'transparent', 
                                margin: '20px',
                                padding:'5px',
                                height: '90px',
                                width: '90px',
                                '&:hover':{
                                    bgcolor:'transparent'
                                } ,
                                '&:focus':{
                                    
                                    animation:`${itemFocusedAnimation} 2s ease-in-out infinite`
                                },
                                '&.MuiFab-root':{
                                    boxShadow:'none'
                                }
                            }} 
                            disableFocusRipple 
                            disableRipple
                            href='https://www.facebook.com/104318951839324sEya' rel='norefferer noopener' target="_blank"
                        >
                            <Facebook 
                                sx={{
                                    height:'80px', width:'80px', 
                                    color:'white',
                                    padding: '5px',
                                    '&:hover':{
                                        color: '#c0c0c0'
                                    } 
                                }} 
                            />
                        </Fab>
                     
                        <Fab
                            aria-label='Follow us on Instagram in a new tab'
                            sx={{
                                bgcolor:'transparent',
                                margin: '20px',
                                padding:'5px',
                                height: '90px',
                                width: '90px',
                                '&:hover':{
                                    bgcolor:'transparent'
                                },
                                '&:focus':{
                                    animation:`${itemFocusedAnimation} 2s ease-in-out infinite`
                                },
                                '&.MuiFab-root':{
                                    boxShadow:'none'
                                }
                            }}  
                            disableFocusRipple
                            disableRipple
                            href='https://www.instagram.com/lulusbarbershop/' rel='norefferer noopener' target="_blank"
                        >
                            <Instagram   
                                sx={{
                                    height:'80px', width:'80px',
                                    padding: '5px',
                                    color: 'white',
                                    '&:focus':{
                                        outline:'1px solid white',
                                        animation:`${itemFocusedAnimation} 2s ease-in-out infinite`
                                    },
                                    '&:hover':{
                                        color: '#c0c0c0'
                                    }
                                }}
                            />
                        </Fab>

                    </Box>
                </Grid>
                <Grid item sx={{maxWidth:'1200px', justifyContent:'center', alignItems:'center'}} xs={12}>
                    <ShopCarousel/>
                </Grid>
            </Grid>
           
        </Box>
    )
}