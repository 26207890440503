import { keyframes } from "@emotion/react"

export const  itemFocusedAnimation = keyframes`

0% { 
    opacity: 1;
    background-position: 70% 70%;
    outline: 1px solid rgba(255,255,255,.3);
}

50% { 
    opacity: 1;
    background-position: 10% 30%;
    outline: 1px solid rgba(255,255,255,1);
}



100% { 
    opacity: 1;
    background-position: 0% 30%;
    outline: 1px solid rgba(255,255,255,.3);
}

`