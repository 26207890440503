

import { createRef, useEffect, useState } from "react"
import { Grid } from "@mui/material"

interface FrameRef{
    node: HTMLIFrameElement;
}

export const BookAnAppointment = () => {

    
    const [lastHeight, setLastHeight] = useState(0)
    const bookingRef = createRef<HTMLIFrameElement>()

    useEffect(() => {
        
        // const sqfr = document.getElementById("square-iframe")
        // sqfr?.addEventListener('', (e)=> {console.log(e.target)})
        bookingRef.current?.contentDocument?.addEventListener('click', ()=>{console.log('well')})
    },[bookingRef])

    // const handleResize = useCallback((iframe: RefObject<HTMLIFrameElement>) => {
    //     const height = document.getElementById('square-iframe')?.ownerDocument.body.scrollHeight ?? 0

    //     console.log(`height: ${height}`)
    //     if (height !== 0) {
    //         setLastHeight(height);
    //     }
    //   }, []);

    //   var monitor = setInterval(function(){
    //     var elem = document.activeElement;
        
    //     if(elem && elem.tagName == 'IFRAME'){
    //         console.log('something was clicked')
    //         clearInterval(monitor);
    //     }
    // }, 100);

    //   useEffect(() => {
    //     //handleResize(bookingRef)
    //     console.log(bookingRef.current?.contentWindow)
 
    //     bookingRef.current?.addEventListener('load',()=>{ console.log('something changed'); setLastHeight(bookingRef.current?.contentDocument?.body.scrollHeight ?? 0);})
    // }, [handleResize, bookingRef]);

    return(

        <Grid 
            container 
            
            sx={{filter:'invert(91.5%)', width:'90vw', marginBottom:'40px', marginTop:'200px',  height:'auto', minHeight:{xs:'60vh', md:'1300px'}, overflow:'auto'}}
        >


                <iframe 
                    id='square-iframe' 
                    style={{ display: 'flex', flex: 1, border: 'none', overflow:'auto'}} 
                    ref={bookingRef}
                    
                    // onLoad={() => handleResize(bookingRef)}
                    // onLoadCapture={() => handleResize(bookingRef)}
                    src='https://squareup.com/appointments/book/a1deb5e0-435f-4d8b-8358-0c5b7e38f793/2WYPSWE8ZTKKN/start'
                    
                
                >
                </iframe>

                {/* <button onClick={()=>Demodal.open(squareBookingModal)}>
                    Book Now
                </button>  */}
          
            
        </Grid>
                

                
        
    
    )
}


//https://square.site/appointments/buyer/widget/a1deb5e0-435f-4d8b-8358-0c5b7e38f793/2WYPSWE8ZTKKN.js