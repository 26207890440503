import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { Box, CssBaseline, Grid, ThemeProvider, createTheme } from "@mui/material"
import { Home } from './pages/Home';
import { Services } from './pages/Services';
import { BookAnAppointment } from './pages/BookAnAppointment';
import "./App.css"
import { ResponsiveAppBar } from './components/navbar/ResponsiveAppbar';
import { Demodal } from 'demodal';
import { BackToTopButton } from './components/common/BackToTopButton';
import { Footer } from './components/common/Footer';

const darkTheme = createTheme({

  palette: {
    background:{
      paper: '#161616',
      default: '#161616'
    },
    text:{
      primary: '#f7f7f7',
      secondary: '#d0d0d0'
    },
  },
  typography: { 
    fontFamily: ["Montserrat", "Arial", "sans-serif"].join(","), 
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});


function App() {


  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />

      <Box sx={{display:'flex', alignItems:'center', justifyContent: 'center', width:'100%', overflowX:'clip'}}>
        <Router>
          <ResponsiveAppBar/>
          <Grid sx={{marginTop:'100px'}}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/booking" element={<BookAnAppointment />} />
              <Route path="/services" element={<Services />} />
              {/* <Route path="/about" element={<AboutUs />} /> */}
            </Routes>
          </Grid>
        </Router>

        <BackToTopButton/>
        <Demodal.Container/>
        
      </Box>
      <Footer/>
    </ThemeProvider>
  )
}

export default App