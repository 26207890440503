import Lulu from '../../assets/images/lulu.webp'
import Moses from '../../assets/images/moses.webp'
import Angel from '../../assets/images/angel.webp'
// import LuluInvert from '../../assets/images/lulu-inverted.webp'
import Danny from '../../assets/images/danny.webp'

export interface Barber {
    name: string,
    bookingUrl: string,
    image: string
}

export const barbers: Barber[] = [
    {
        name: 'Lulu',
        bookingUrl: 'https://squareup.com/appointments/buyer/widget/ys0bxddu3du8zj/2WYPSWE8ZTKKN',
        image: Lulu,
    },
    {
        name: 'Moses',
        bookingUrl: 'https://square.site/appointments/buyer/widget/yk206anh7l7u1p/9X5BKE90G9AJC',
        image: Moses,
    },
    {
        name: 'Danny',
        bookingUrl: 'https://square.site/appointments/buyer/widget/4o2ekucfwai977/L560Q0W22YB8R',
        image: Danny,
    },
    // {
    //     name: 'Angel',
    //     bookingUrl: 'https://squareup.com/appointments/buyer/widget/mpyrxwnfpgyis0/2WYPSWE8ZTKKN',
    //     image: Angel,
    // },

]

