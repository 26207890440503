import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  MenuItem,
  Drawer,
  Grid,
  Fab
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Close } from '@mui/icons-material';
import Logo from '../../assets/images/logo.webp'
import { Demodal } from 'demodal';
import { SquareBookingModal } from '../booking-modal/SquareBookingModal';
import { itemFocusedAnimation } from '../common/focus';


const pages = [
  { title: 'HOME', linkTo: '/' },
 
  // { title: 'ABOUT US', linkTo: '/about'},
  { title: 'SERVICES', linkTo: '/services'},
  { title: 'BOOK AN APPOINTMENT', linkTo: '/booking' }
];


export function ResponsiveAppBar() {

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  
  const [trigger2, setTrigger2] = React.useState(true)



  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = currPos.y === 0
    if(!isShow){
      setTrigger2(true)
    }
    else{
      setTrigger2(false)
    }
  }, [])


  const handleOpenBooking = () =>{
    handleCloseNavMenu()
    Demodal.open(SquareBookingModal)
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {

    setAnchorElNav(null);
  };

  return (
    <>
     
      <AppBar 
        position='fixed' 
        sx={{ backgroundColor: 'rgb(22, 22, 22)', height:trigger2?'88px':'110px' }} 
        id='back-to-top-anchor'
      >

        <Typography 
          textAlign='center' 

          sx={{
            display: !trigger2? 'block': 'none', 
            bgcolor:'black', 
            transition:'all linear .5s', 
            fontSize:'16px', 
            color: '#e2e2e2',
            paddingTop: '4px',
            paddingBottom: '4px'
          }}
        >
          (626) 578-9929 or text Lulú (626) 379-0535
        </Typography>
 
        <Grid
          container
          flexGrow={1}
        >
          <Toolbar disableGutters sx={{width:'100%'}}>
    
            <Grid sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', lg:'none' }, justifyContent: 'flex-start', alignItems: 'center' }}>
              
              <Grid item justifyContent='center' alignItems='center' >

                <IconButton
                  size="large"
                  aria-label="Open navigation menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              </Grid>

              <Grid 
                item
                
                justifyContent='center' alignItems='center'
                sx={{
                  position: 'absolute',
                  left:'calc(50% - 94px)',
                  transition:'all 0.5s ease-in-out',
                  transform:  trigger2? `scale(.8) translateY(0)`:`scale(0.8) translateY(25%)`,
                  '@media screen and (min-width: 300px)': { 
                    transform:  trigger2? `scale(.9) translateY(0)`:`scale(1) translateY(25%)`
                  },
                  '@media screen and (min-width: 350px)': { 
                    transform:  trigger2? `scale(1) translateY(0)`:`scale(1.5) translateY(25%)`
                  },
                  '@media screen and (min-width: 600px)': { 
                    transform:  trigger2? `scale(1) translateY(0)`:`scale(1.8) translateY(25%)`
                  },
                  '@media screen and (min-width: 850px)': { 
                    transform:  trigger2? `scale(1) translateY(0)`:`scale(2) translateY(28%)`
                  }
                }}
              >
                <img
                  alt='Lulus Barbershop Logo' 
                  src={Logo} 
                  style={{ 
                    height:'88px', width: 'auto', maxWidth:'300px', 
                    alignItems:'center', verticalAlign:'middle', 
                  }}
                  onClick={() => {navigate(pages[0].linkTo);}}
                />


              </Grid>
              <Drawer
                id="menu-appbar"
                anchor='left'
                keepMounted
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  width: '100vw',
                  textAlign: 'center',
                  '& .MuiDrawer-paper': {
                    bgcolor: 'rgb(22,22,22)',
                    width: '100vw',
                    boxSizing: 'border-box',
                    paddingTop: '60px',
                    backdropFilter: 'blur(7px)'
                  },
                }}
              >
                <Fab 
                  size="small" 
                  aria-label="close menu" 
                  sx={{
                    position: 'absolute', top: '14px', right: '14px',
                    '&:hover':{
                      bgcolor: 'rgba(140,140,140)'
                    }
                  }}
                  onClick={()=>handleCloseNavMenu()}
                >
                  <Close/>
                </Fab>
                <Grid container justifyContent='center' flexDirection='column'>
                  {pages.map((page) => (
                    <MenuItem 
                      key={page.title}
                      aria-label={page.linkTo === '/booking'?`Book an appointment via the square booking modal`:`navigate to ${page.title}`} 
                      onClick={() => {page.linkTo === '/booking'?handleOpenBooking():navigate(page.linkTo); handleCloseNavMenu();}}
                      href={page.linkTo} 
                      disabled={page.linkTo === window.location.pathname} 
                      sx={{

                        color: 'rgb(247,247,247)',
                        cursor: 'pointer',
                        fontSize: '22px',
                        borderBottom: '1px solid rgb(80,80,80)',
                        marginLeft: '20px', marginRight: '20px',
                        whiteSpace: 'wrap',
                        textAlign: 'left',
                        
                        '&:hover':{
                          color: 'rgb(140,140,140)',
                          borderBottom: '1px solid rgb(80,80,80)',
                        },
                        '&:active:hover':{
                          color: 'rgb(247,247,247)'
                        },
                        '&:focus':{
                          animation:`${itemFocusedAnimation} 2s ease-in-out infinite`
                        },
                        '&.Mui-disabled':{
                          color: 'rgb(255,255,255)',
                          cursor: 'default',
                          fontWeight: 'bold',
                          textDecoration: 'underline',
                          borderBottom: '1px solid rgb(160,160,160)',
                          animation: 'none!important'
                        },
                      
                      }}
                      tabIndex={0}
                    >
                      {page.title}
                    </MenuItem>
                  ))}
                </Grid>
              </Drawer>
            </Grid>

            <Grid  sx={{ flexGrow: 1, display: { xs: 'none', md: 'none', lg: 'flex' }, justifyContent: 'space-between' }} >

              <Grid item xs={4} display='flex' justifyContent='flex-end' alignItems='center'>
                <Grid item >
                  <MenuItem
                    tabIndex={0}
                    aria-label={`Navigate to ${pages[0].title}`} 
                    key={pages[0].title} 
                    onClick={() => {navigate(pages[0].linkTo); handleCloseNavMenu();}}
                    href={pages[0].linkTo} 
                    disabled={pages[0].linkTo === window.location.pathname} 
                    sx={{
                      zIndex: 50,
                      fontFamily:'Montserrat, Arial, sans-serif',
                      color: 'rgb(247,247,247)',
                      cursor: 'pointer',
                      fontSize: { xs: '14px', lg: '16px', xl: '18px' },
                      '&:focus':{
                        animation:`${itemFocusedAnimation} 2s ease-in-out infinite`
                      },
                      '&:hover':{
                        color: 'rgb(170,170,170)'
                      },
                      '&:active:hover':{
                        color: 'rgb(247,247,247)'
                      },
                      '&.Mui-disabled':{
                        color: 'rgb(255,255,255)',
                        cursor: 'default',
                        fontWeight: 'bold',
                        borderBottom: '1px solid rgb(240,240,240)',
                        animation: 'none!important'
                      },
                    }}
                  >
                    {pages[0].title}
                  </MenuItem>
                </Grid>

                <Grid item>
                  <MenuItem 
                    tabIndex={0}
                    aria-label={`Navigate to ${pages[1].title}`}
                    key={pages[1].title} 
                    onClick={() => {navigate(pages[1].linkTo); handleCloseNavMenu();}}
                    href={pages[1].linkTo} 
                    disabled={pages[1].linkTo === window.location.pathname} 
                    
                    sx={{
                      zIndex: 50,
                      fontFamily:'Montserrat, Arial, sans-serif',
                      color: 'rgb(247,247,247)',
                      cursor: 'pointer',
                      fontSize: { xs: '14px', lg: '16px', xl: '18px' },
                      '&:focus':{
                        animation:`${itemFocusedAnimation} 2s ease-in-out infinite`
                      },
                      textAlign: 'center',
                      '&:hover':{
                        color: 'rgb(170,170,170)'
                      },
                      '&:active:hover':{
                        color: 'rgb(247,247,247)'
                      },
                      '&.Mui-disabled':{
                        color: 'rgb(255,255,255)',
                        cursor: 'default',
                        fontWeight: 'bold',
                        borderBottom: '1px solid rgb(240,240,240)',
                        animation: 'none!important'
                      },
                    }}
                  >
                    {pages[1].title}
                  </MenuItem>
                </Grid>
              </Grid>

              <Grid 
                item xs={3} 
                justifyContent='center' alignItems='center' display='flex' 
                sx={{
                  transition:'all 0.5s ease-in-out',
                  '@media screen and (min-width: 1200px)': { 
                    transform:  trigger2? `scale(1) translateY(0)`:`scale(2) translateY(25%)`
                  },
                  '@media screen and (min-width: 1550px)': { 
                    transform:  trigger2? `scale(1) translateY(0)`:`scale(2.2) translateY(28%)`
                  },
                  '@media screen and (min-width: 1800px)': { 
                    transform:  trigger2? `scale(1) translateY(0)`:`scale(2.5) translateY(30%)`
                  }
                }}
              >
                <img
                  alt='Lulus Barbershop Logo'  
                  src={Logo} 
                  style={{ 
                    height:'88px', width: 'auto', maxWidth:'300px', 
                    alignItems:'center', verticalAlign:'middle',
                    cursor:'pointer' 
                    
                  }}
                  onClick={() => {navigate(pages[0].linkTo);}}
                />
              </Grid>

              <Grid item xs={4} display='flex' justifyContent='flex-start' alignItems='center'>
                <Grid item>
                  <MenuItem
                    aria-label='Book an appointment via the square booking modal' 
                    tabIndex={0}
                    key={pages[2].title} 
                    onClick={() => {handleOpenBooking()}}
                    href={pages[2].linkTo} 
                    disabled={pages[2].linkTo === window.location.pathname} 
                    sx={{
                      zIndex: 50,
                      fontFamily:'Montserrat, Arial, sans-serif',
                      color: 'rgb(247,247,247)',
                      cursor: 'pointer',
                      fontSize: { xs: '14px', lg: '16px', xl: '18px' },
                      '&:focus':{
                        animation:`${itemFocusedAnimation} 2s ease-in-out infinite`
                      },
                      '&:hover':{
                        color: 'rgb(170,170,170)'
                      },
                      '&:active:hover':{
                        color: 'rgb(247,247,247)'
                      },
                      '&.Mui-disabled':{
                        color: 'rgb(255,255,255)',
                        cursor: 'default',
                        fontWeight: 'bold',
                        borderBottom: '1px solid rgb(240,240,240)',
                        animation: 'none!important'
                      },
                    }}
                  >
                    {pages[2].title}
                  </MenuItem>
                </Grid>
{/*             
                <Grid item>
                  <MenuItem 
                    key={pages[3].title} 
                    onClick={() => {navigate(pages[3].linkTo); handleCloseNavMenu();}}
                    href={pages[3].linkTo} 
                    disabled={pages[3].linkTo === window.location.pathname} 
                    sx={{
                      zIndex: 50,
                      fontFamily:'Montserrat, Arial, sans-serif',
                      color: 'rgb(247,247,247)',
                      cursor: 'pointer',
                      fontSize: { xs: '14px', lg: '16px', xl: '18px' },
                      
                      '&:hover':{
                        color: 'rgb(170,170,170)'
                      },
                      '&:active:hover':{
                        color: 'rgb(247,247,247)'
                      },
                      '&.Mui-disabled':{
                        color: 'rgb(255,255,255)',
                        cursor: 'default',
                        fontWeight: 'bold',
                        borderBottom: '1px solid rgb(240,240,240)',
                      },
                    }}
                  >
                    {pages[3].title}
                  </MenuItem>
                </Grid> */}
              </Grid>
                  


              {/* <Grid container sx={{ width: '140px!important' }} >
                {socials.map((page) => (
                  <Grid key={page.title} style={{ height: '30px', width: page.title === 'Gitbooks' ? '42px' : '30px', marginTop: 'auto', marginBottom: 'auto', marginRight: '5px', marginLeft: '5px' }}>

                    <a href={page.linkTo} rel="noreferrer noopener" target="_blank">
                      <div
                        style={{ height: '100%' }}
                        className={`top-icons ${page.className}`}>
                      </div>
                    </a>
                  </Grid>
                ))}
              </Grid> */}

            </Grid>
          </Toolbar>
        </Grid>
      </AppBar>
    </>
  );
}
