import { Box, Typography } from "@mui/material";


export const Footer = () => (
    <Box sx={{justifyContent:'center', alignItems:'center', width:'100%', textAlign:'center', paddingBottom:'80px'}}>

        <Typography fontSize='18px' sx={{marginTop:'100px'}}>
            <span style={{ fontWeight:'bold', color:'#f7f7f7' }}>Lulú's Barbershop</span> <br/><br/>

            <span style={{ color:'#f7f7f7'}}>180 Rosemead Boulevard, Pasadena, California 91107, United States</span><br/><br/>

            <span style={{ color:'#8c8c8c'}}>(626) 578-9929</span><br/><br/><br/>

            <span style={{ color:'#a9a9a9'}}>Copyright © 2024 Lulú's Barbershop - All Rights Reserved.</span><br/>

        </Typography>
    </Box>
)