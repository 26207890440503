import { Box } from "@mui/material"
import Carousel from "react-material-ui-carousel"
import G1 from '../../assets/images/gallery-image-1.webp'
import G2 from '../../assets/images/gallery-image-2.webp'
import G3 from '../../assets/images/gallery-image-3.webp'
import G4 from '../../assets/images/gallery-image-4.webp'
import G5 from '../../assets/images/gallery-image-5.webp'
import G6 from '../../assets/images/gallery-image-6.webp'
import G7 from '../../assets/images/gallery-image-7.webp'
import G8 from '../../assets/images/gallery-image-8.webp'
import G9 from '../../assets/images/gallery-image-9.webp'
import G10 from '../../assets/images/gallery-image-10.webp'
import G11 from '../../assets/images/gallery-image-11.webp'

const galleryItems = [
    <img 
        src={G1} alt={`Gallery ${0}`}
        style={{ width: '100%', height: '100%', display: 'flex'}}
    />,
    <img 
        src={G2} alt={`Gallery ${1}`}
        style={{ width: '100%', height: '100%', display: 'flex'}}
    />,
    <img 
        src={G3} alt={`Gallery ${1}`}
        style={{ width: '100%', height: '100%', display: 'flex'}}
    />,
    <img 
        src={G4} alt={`Gallery ${1}`}
        style={{ width: '100%', height: '100%', display: 'flex'}}
    />,
    <img 
        src={G5} alt={`Gallery ${1}`}
        style={{ width: '100%', height: '100%', display: 'flex'}}
    />,
    <img 
        src={G6} alt={`Gallery ${1}`}
        style={{ width: '100%', height: '100%', display: 'flex'}}
    />,
    <img 
        src={G7} alt={`Gallery ${1}`}
        style={{ width: '100%', height: '100%', display: 'flex'}}
    />,
    <img 
        src={G8} alt={`Gallery ${1}`}
        style={{ width: '100%', height: '100%', display: 'flex'}}
    />,
    <img 
        src={G9} alt={`Gallery ${1}`}
        style={{ width: '100%', height: '100%', display: 'flex'}}
    />,
    <img 
        src={G10} alt={`Gallery ${1}`}
        style={{ width: '100%', height: '100%', display: 'flex'}}
    />,
    <img 
        src={G11} alt={`Gallery ${1}`}
        style={{ width: '100%', height: '100%', display: 'flex'}}
    />
]

export const ShopCarousel = () => {

    return(
        <Carousel
            swipe
            navButtonsAlwaysVisible
            IndicatorIcon={galleryItems}
            fullHeightHover={false}
            indicatorIconButtonProps={{
                className: 'carousel-indicatorProps'
            }}
            indicatorContainerProps={{
                style:{
                    justifyContent:'center',
                    margin:'auto', padding: 'auto', marginTop:'15px',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    maxWidth: '80%'
                },
                className: 'carousel-containerProps'
            }}
            activeIndicatorIconButtonProps={{
                style:{
                    opacity: 1,
                    transform: 'scale(1.2)'
                }
            }}
            sx={{maxWidth:'800px', justifyContent:'center', alignItems:'center', margin:'auto', padding:'auto'}}
        >
            {galleryItems.map((item, index)=>
                <Box key={index} sx={{height:'50vh', maxHeight:'800px', minHeight:'300px', width:'auto', aspectRatio:'0.815', alignItems:'center', margin:'auto', padding:'auto'}}>
                    {
                        item
                    }
                </Box>
            )
            }
        </Carousel>
    )
}